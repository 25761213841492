import { Injectable } from '@angular/core';
import { VasCode } from '@enums/vas-enrolments.enum';
import { LoginLibService } from '@mx51/login-lib';

/**
 * Query key guidelines:
 * 1. Keys used pre-authentication belong in the public keys prop.
 * 2. Keys used post-authentication belong in the secure keys prop. These keys will be prefixed with the current
 *    session id automatically. This ensures sensitive data cannot leak between user sessions.
 * 3. Branch keys by feature or type of thing at the most granular level that makes sense. Specify an all() key. The
 *    all() key should only serve as the base for other keys and not be called directly except when invalidating
 *    queries. Invalidating via the all() key is especially useful for invalidating all cached pages of a paginated
 *    list.
 *
 *    Do - branch by feature:
 *    ```
 *    {
 *      disputes: {
 *        all: () => ['disputes']
 *      }
 *      transactions: {
 *        all: () => ['transactions']
 *      }
 *     }
 *     ```
 *
 *    Avoid - mixing features:
 *    ```
 *    {
 *      disputes: () => ['disputes']
 *      transactions: () => ['transactions']
 *    }
 *    ```
 *
 *    Do - use the all() key as a base for other keys:
 *    ```
 *    {
 *      disputes: {
 *        all: () => ['disputes'],
 *        list: () => [...disputes.all(), 'list']
 *      }
 *    }
 *    ```
 *
 *    Do - query keys for specific actions:
 *    ```
 *    queryService.useQuery(queryKeys.disputes.list(), () => {})
 *    ```
 *
 *    Avoid - querying the all() key directly:
 *    ```
 *    queryService.useQuery(queryKeys.disputes.all(), () => {})
 *    ```
 *
 *    Do - leverage the all() key to invalidate ALL queries for a feature:
 *    ```
 *    queryService.invalidateQueries(queryKeys.disputes.all())
 *    ```
 *
 *  4. Branch individual entities separately. Require the entity id as a parameter at the root of the branch. For
 *     individual entities specify a byId() key instead of an all(). The byId() key should be used to get an entity by
 *     id.
 *
 *     Do - branch individual entities separately:
 *     ```
 *     {
 *      dispute: (disputeId: string) => {
 *        byId: () => ['dispute', disputeId]
 *      },
 *      disputes: {
 *        all: () => ['disputes'],
 *        list: () => [...disputes.all(), 'list']
 *      }
 *     }
 *     ```
 *
 *     Avoid - mixing individual entities with features:
 *     ```
 *     {
 *      disputes: {
 *        all: () => ['disputes'],
 *        byId: (disputeId: string) => [...disputes.all(), disputeId],
 *        list: () => [...disputes.all(), 'list']
 *      }
 *     }
 *     ```
 *
 *    Do - query the byId() key directly:
 *    ```
 *    queryService.useQuery(queryKeys.dispute(disputeId).byId(), () => {})
 *    ```
 *
 *   Do - leverage the byId() key to invalidate ALL queries for an entity:
 *   ```
 *   queryService.useQuery(queryKeys.dispute(disputeId).byId(), () => {})
 *   ```
 *
 * 5. The byId() key should also serve as the base for any other keys directly related to the entity.
 *
 *   Do - branch related entities off of the byId() key:
 *   ```
 *   {
 *    dispute: (disputeId: string) => {
 *      byId: () => ['dispute', disputeId]
 *      comment: () => [...dispute(disputeId).byId(), 'comment']
 *    }
 *   }
 *   ```
 *
 * 6. Nest to any level to keep things organised.
 *
 *  Avoid - lumping all keys in a single bucket:
 *   ```
 *  {
 *    dispute: (disputeId: string) => {
 *      byId: () => ['dispute', disputeId]
 *      listActivities: () => [...dispute(disputeId).byId(), 'list-activities']
 *      searchActivities: () => [...dispute(disputeId).byId(), 'search-activities']
 *    }
 *  }
 *  ```
 *
 *  Do - nest keys when required:
 *  ```
 *  {
 *    dispute: (disputeId: string) => {
 *      byId: () => ['dispute', disputeId]
 *      activities: {
 *        all: () => [...dispute(disputeId).byId(), 'activities'],
 *        list: () => [...dispute(disputeId).activities.all(), 'list']
 *        search: () => [...dispute(disputeId).activities.all(), 'search']
 *      }
 *    }
 *  }
 *  ```
 *
 * 7. Only add keys for mutations if you have a specific reason to do so. The two use cases for mutation keys are
 *    inheriting defaults and debugging.
 */
@Injectable({
  providedIn: 'root',
})
export class QueryKeys {
  public = {}; // This is where I would put my public query keys, if I had any!!
  secure = {
    all: ['session', this.sessionId],
    featureFlags: () => [...this.secure.all, 'feature-flags'],
    me: {
      all: () => [...this.secure.all, 'me'],
      userProfile: () => [...this.secure.me.all(), 'user-profile'],
    },
    org: (orgId: string) => {
      return {
        byId: () => [...this.secure.all, 'organisation', orgId],
        dispute: (disputeId: string) => {
          return {
            byId: () => [...this.secure.org(orgId).byId(), 'dispute', disputeId],
            activities: {
              all: () => [...this.secure.org(orgId).dispute(disputeId).byId(), 'activities'],
              list: (options: object) => [
                ...this.secure.org(orgId).dispute(disputeId).activities.all(),
                'list',
                options,
              ],
            },
            evidence: {
              all: () => [...this.secure.org(orgId).dispute(disputeId).byId(), 'evidence'],
              list: () => [...this.secure.org(orgId).dispute(disputeId).evidence.all(), 'list'],
            },
          };
        },
        groups: {
          all: () => [...this.secure.org(orgId).byId(), 'groups'],
          list: () => [...this.secure.org(orgId).groups.all(), 'list'],
        },
        insights: {
          all: () => [...this.secure.org(orgId).byId(), 'insights'],
          average_purchase_amount: {
            all: () => [...this.secure.org(orgId).insights.all(), 'average_purchase_amount'],
            list: (options: object) => [...this.secure.org(orgId).insights.average_purchase_amount.all(), options],
          },
          transactions: {
            all: () => [...this.secure.org(orgId).insights.all(), 'transactions'],
            list: (options: object) => [...this.secure.org(orgId).insights.transactions.all(), options],
          },
        },
        member: (memberId: string) => {
          return {
            byId: () => [...this.secure.org(orgId).byId(), 'member', memberId],
          };
        },
        memberships: {
          all: () => [...this.secure.org(orgId).byId(), 'memberships'],
          list: (options: object) => [...this.secure.org(orgId).memberships.all(), 'list', options],
        },
        merchantFacility: (merchantFacilityId: string) => {
          return {
            byId: () => [...this.secure.org(orgId).byId(), 'merchant-facility', merchantFacilityId],
            members: {
              all: () => [...this.secure.org(orgId).merchantFacility(merchantFacilityId).byId(), 'members'],
              list: (options: object) => [
                ...this.secure.org(orgId).merchantFacility(merchantFacilityId).members.all(),
                'list',
                options,
              ],
            },
          };
        },
        merchantFacilities: {
          all: () => [...this.secure.org(orgId).byId(), 'merchant-facilities'],
          list: () => [...this.secure.org(orgId).merchantFacilities.all(), 'list'],
        },
        serviceRequests: {
          all: () => [...this.secure.org(orgId).byId(), 'service-requests'],
          list: (options: object) => [...this.secure.org(orgId).serviceRequests.all(), 'list', options],
        },
        serviceRequestTypes: {
          all: () => [...this.secure.org(orgId).byId(), 'service-request-types'],
          list: () => [...this.secure.org(orgId).serviceRequestTypes.all(), 'list'],
        },
        settlements: {
          all: () => [...this.secure.org(orgId).byId(), 'settlements'],
          list: (options: object) => [...this.secure.org(orgId).settlements.all(), 'list', options],
        },
        terminal: {
          all: () => [...this.secure.org(orgId).byId(), 'terminal'],
          byMid: (mid: string) => [...this.secure.org(orgId).terminal.all(), mid],
        },
        transactions: {
          all: () => [...this.secure.org(orgId).byId(), 'transactions'],
          linkedTransactions: (linkedTransactionId: string) => [
            ...this.secure.org(orgId).transactions.all(),
            'linked-transactions',
            linkedTransactionId,
            'list',
          ],
          terminalTransactionsReport: {
            all: () => [...this.secure.org(orgId).transactions.all(), 'terminal-transactions-report'],
            list: (options: object) => [
              ...this.secure.org(orgId).transactions.terminalTransactionsReport.all(),
              'list',
              options,
            ],
          },
        },
        users: {
          all: () => [...this.secure.org(orgId).byId(), 'users'],
          invite: () => [...this.secure.org(orgId).users.all(), 'invite'],
        },
        vasGenericAgreements: {
          all: () => [...this.secure.org(orgId).byId(), 'vas-generic-agreements'],
          byAgreementId: (agreementId: string) => [...this.secure.org(orgId).vasEnrolment.all(), agreementId],
        },
        vasEnrolment: {
          all: () => [...this.secure.org(orgId).byId(), 'vas-enrolment'],
          byCode: (vasCode: VasCode) => [...this.secure.org(orgId).vasEnrolment.all(), vasCode],
          offers: (vasCode: VasCode) => [...this.secure.org(orgId).vasEnrolment.byCode(vasCode), 'offers'],
          preOptInData: (vasCode: VasCode) => [
            ...this.secure.org(orgId).vasEnrolment.byCode(vasCode),
            'pre-opt-in-data',
          ],
        },
        vasEnrolments: {
          all: () => [...this.secure.org(orgId).byId(), 'vas-enrolments'],
          list: () => [...this.secure.org(orgId).vasEnrolments.all(), 'list'],
        },
      };
    },
  };

  private get sessionId(): string {
    return this.loginLibService.getSessionId();
  }

  constructor(private loginLibService: LoginLibService) {}
}
