import { APIResponse } from './api';

export interface GetUserProfileResponse extends APIResponse<User> {
  user: User;
}

export interface User {
  first_name: string;
  last_name: string;
  email_address?: string;
  mobile_phone_number?: string;
  provisioned_by_acquirer?: boolean;
  sso_issuer?: SsoIssuer;
}

export enum SsoIssuer {
  Amazon = 'amazon',
  Mx51Okta = 'mx51okta',
}

export const SsoIssuerLabelMap = {
  [SsoIssuer.Amazon]: 'Amazon',
  [SsoIssuer.Mx51Okta]: 'Okta',
};

export enum UserType {
  Acquirer = 'ACQUIRER',
  Merchant = 'MERCHANT',
  Operator = 'OPERATOR',
  ThirdParty = 'THIRD_PARTY',
  Unspecified = 'UNSPECIFIED',
}

export enum UserAccountStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
}

export type UserProfile = Pick<
  User,
  'first_name' | 'last_name' | 'email_address' | 'mobile_phone_number' | 'provisioned_by_acquirer'
>;

export interface UpdateUserProfileResponse extends APIResponse<UserProfile> {}

export type UpdateUserProfileReq = Partial<UserProfile>;
