import { Injectable } from '@angular/core';
import { ReleaseFlag } from '@enums/release-flag.enum';

@Injectable({
  providedIn: 'root',
})
export class ReleaseFlagOverrideService {
  protected readonly enabled = 'enabled';
  protected readonly disabled = 'disabled';

  isFlagSet(releaseFlag: ReleaseFlag): boolean {
    return window.sessionStorage.getItem(this.toStorageKey(releaseFlag)) !== null;
  }

  isFlagEnabled(releaseFlag: ReleaseFlag): boolean {
    const flagState = window.sessionStorage.getItem(this.toStorageKey(releaseFlag));
    return flagState === this.enabled;
  }

  toggleFlagOverride(releaseFlag: ReleaseFlag): void {
    window.sessionStorage.setItem(
      this.toStorageKey(releaseFlag),
      this.isFlagSet(releaseFlag) ? (this.isFlagEnabled(releaseFlag) ? this.disabled : this.enabled) : this.enabled
    );
  }

  private toStorageKey(releaseFlag: ReleaseFlag): string {
    return `release_flag_override.${releaseFlag}`;
  }
}
