import { CommonModule } from '@angular/common';
import { AfterContentInit, AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconModule } from '@mx51/shared-components';
import { A11yService } from 'app/services/a11y.service';
import { FeatureFlagService } from 'app/services/feature-flag.service';

@Component({
  selector: 'app-main-page-layout',
  templateUrl: './main-page-layout.component.html',
  standalone: true,
  imports: [CommonModule, IconModule, RouterModule],
})
export class MainPageLayoutComponent implements AfterContentInit, AfterViewInit {
  @ViewChild('main', { static: true }) main: ElementRef;

  @Input() pageTitle: string;

  constructor(public featureFlags: FeatureFlagService, private a11yService: A11yService) {}

  ngAfterContentInit() {
    this.scrollToHeader();
  }

  // TODO: autofocus on main added here to keep existing features the same
  // during migration. This will be removed once all pages have been migrated
  // to use this component. https://mx51.atlassian.net/browse/FEBAU-586
  ngAfterViewInit() {
    (window as Window).scrollTo(0, 0);
    this.a11yService.autoFocusOnMain(this.main);
  }

  scrollToHeader() {
    const mainHeader = document.querySelector('#main-header');
    mainHeader.scrollIntoView({
      behavior: 'instant',
      block: 'nearest',
    });
  }
}
