import { Injectable } from '@angular/core';
import { EnvironmentVariables } from '@interfaces/environment-variables';

export function readEnvironmentVariablesFromDocument(): EnvironmentVariables {
  try {
    const injectedElement = document.querySelector<HTMLElement>(
      'script[type="application/json"][data-inline-id="msp-md-env"]'
    );

    if (injectedElement) {
      return JSON.parse(injectedElement.textContent);
    }
  } catch {}

  return {};
}

@Injectable({
  providedIn: 'root',
})
export class EnvironmentVariableService {
  private environmentVariablesCore: EnvironmentVariables = {};

  get environmentVariables(): EnvironmentVariables {
    return this.environmentVariablesCore;
  }

  init(): void {
    this.environmentVariablesCore = readEnvironmentVariablesFromDocument();
  }
}
