export enum FeatureKey {
  DashboardGraph = 'dashboard.business_overview_graph',
  Transactions = 'transactions',
  Settlements = 'settlements',
  Disputes = 'disputes',
  DisputesViaFileIngestion = 'disputes.via_file_ingestion',
  UserManagement = 'user_management',
  UserManagementDisclaimer = 'user_management.disclaimer_acknowledgement',
  BusinessSettings = 'business_settings',
  InstoreApi = 'instore_api',
  MerchantReports = 'merchant_reports',
  MerchantServiceRequests = 'merchant_service_requests',
  TogglePaymentLogos = 'toggle_payment_logos',
  InsightsService = 'insights_service',
  InsightsAveragePurchaseGraph = 'insights.average_purchase_graph',
  InsightsChargebackMetrics = 'insights.chargeback_metrics',
  InsightsNewVsReturningCustomersGraph = 'insights.new_vs_returning_customers_graph',
  MerchantLogoUpload = 'merchant_logo_upload',
  TerminalTransactionsReport = 'terminal_transaction_report',
  VerticalNavigation = 'vertical_navigation',
  MerchantFacilityAccess = 'merchant_facility_access',
  VasConnectService = 'vas_connect_service',
  EditOwnContactInfo = 'edit_own_contact_info',
  EditOrgMemberContactInfo = 'edit_org_member_contact_info',
}

export enum FeatureStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type FeatureList = {
  key: FeatureKey;
  status: FeatureStatus;
}[];

export interface GetFeaturesResp {
  data: FeatureList;
}
