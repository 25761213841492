import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReleaseFlag } from '@enums/release-flag.enum';
import { isReleaseFlagEnvironment } from '@modules/shared/utils/release-flag-environments';
import { IconComponent, provideIcons } from '@mx51/chameleon-icons-angular';
import { OutlineFlag } from '@mx51/chameleon-icons-core';
import { ButtonComponent, CheckboxComponent, IconButtonComponent } from '@mx51/chameleon-ui-angular';
import { ReleaseFlagOverrideService } from 'app/services/release-flag-override.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EnvironmentVariableService } from '../../../../services/environment-variable.service';

@Component({
  standalone: true,
  selector: 'app-release-flags',
  templateUrl: './release-flags.component.html',
  imports: [CheckboxComponent, IconButtonComponent, IconComponent, ButtonComponent, CommonModule],
  providers: [
    provideIcons({
      OutlineFlag,
    }),
  ],
})
export class ReleaseFlagsComponent implements OnDestroy, OnInit {
  private readonly unsubscribe$ = new Subject<void>();

  hasReleaseFlags: boolean;
  isVisible = false;
  isMenuOpen = false;
  releaseFlagKeys: string[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private releaseFlagOverrideService: ReleaseFlagOverrideService,
    private environmentVariableService: EnvironmentVariableService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.releaseFlagKeys = Object.keys(ReleaseFlag);
    this.hasReleaseFlags = this.releaseFlagKeys.length > 0;
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((queryParams) => {
      // Never show release flag button in prod
      const { TENANT } = this.environmentVariableService.environmentVariables;

      if (!isReleaseFlagEnvironment(TENANT)) {
        return false;
      }

      if (queryParams?.showreleaseflags === 'true') {
        this.isVisible = true;
      }
    });
  }

  isFlagEnabled(key: string): boolean {
    return this.releaseFlagOverrideService.isFlagEnabled(ReleaseFlag[key]);
  }

  toggleReleaseFlag(key: string): void {
    this.releaseFlagOverrideService.toggleFlagOverride(ReleaseFlag[key]);
  }
}
