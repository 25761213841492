import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorMessageService as CoreErrorMessageService } from '@mx51/core-tools';
@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  constructor(private errorMessageService: CoreErrorMessageService) {}

  getMessage(err: HttpErrorResponse): string {
    return this.errorMessageService.getError(err).message;
  }
}
