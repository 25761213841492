import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { RouterModule } from '@angular/router';
import { environment } from '@environments/environment';
import { HeroMetricSubtextComponent } from '@modules/secure/dashboard/components/hero-metric/hero-metric-subtext/hero-metric-subtext.component';
import { HeroMetricComponent } from '@modules/secure/dashboard/components/hero-metric/hero-metric.component';
import { BackLinkComponent } from '@modules/secure/shared/main-page-layout/components/back-link/back-link.component';
import { MetricsSectionComponent } from '@modules/secure/shared/main-page-layout/components/metrics-section/metrics-section.component';
import { MainPageLayoutComponent } from '@modules/secure/shared/main-page-layout/main-page-layout.component';
import { CompositeNameComponent } from '@modules/shared/components/composite-name/composite-name.component';
import { ErrorTemplatesComponent } from '@modules/shared/components/error-templates/error-templates.component';
import { FilterAccordionComponent } from '@modules/shared/components/filter-accordion/filter-accordion.component';
import { MidListFilterComponent } from '@modules/shared/components/mid-list-filter/mid-list-filter.component';
import { PermissionOutlineComponent } from '@modules/shared/components/permission-outline/permission-outline.component';
import { TransactionTableWithPaginationComponent } from '@modules/shared/components/transaction-table-with-pagination/transaction-table-with-pagination.component';
import { TerminalNicknamePipe } from '@modules/shared/pipes/terminal-nickname.pipe';
import { IconComponent } from '@mx51/chameleon-icons-angular';
import {
  AlertBodyComponent,
  AlertComponent,
  AlertDescriptionComponent,
  AlertIconComponent,
  AlertTitleComponent,
  BadgeComponent,
  ButtonComponent,
  CardBodyComponent,
  CardComponent,
  CardHeaderComponent,
  CheckboxComponent,
  CheckboxControlComponent,
  CheckboxInputComponent,
  CheckboxLabelComponent,
  CheckboxWrapComponent,
  CloseButtonComponent,
  ComboboxComponent,
  ComboboxOptionBaseComponent,
  ComboboxOptionComponent,
  ComboboxOptionDirective,
  ComboboxSelectionIndicatorCheckboxComponent,
  DividerComponent,
  HelpTextComponent,
  HStackComponent,
  IconButtonComponent,
  ModalBodyComponent,
  ModalComponent,
  ModalFooterComponent,
  ModalHeaderComponent,
  ModalTitleComponent,
  StackComponent,
  VisuallyHiddenComponent,
  VStackComponent,
} from '@mx51/chameleon-ui-angular';
import { ButtonModule, DataViewsModule, IconModule, NumberCounterModule } from '@mx51/shared-components';
import { IMaskModule } from 'angular-imask';
import { PdfJsViewerComponent, PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

import { AlertOrgStatusComponent } from './components/alert-org-status/alert-org-status.component';
import { CheckboxContentToggleComponent } from './components/checkbox-content-toggle/checkbox-content-toggle.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DatePickerDialogComponent } from './components/date-picker-dialog/date-picker-dialog.component';
import { DateTimePickerDialogComponent } from './components/date-time-picker-dialog/date-time-picker-dialog.component';
import { DateTimeRangeDialogComponent } from './components/date-time-range-dialog/date-time-range-dialog.component';
import { DialogActionsComponent } from './components/dialog/dialog-actions';
import { DownloadingFileComponent } from './components/downloading-file/downloading-file.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { InactivityDialogComponent } from './components/inactivity-dialog/inactivity-dialog.component';
import { MenuComponent } from './components/menu/menu.component';
import { MerchantFacilitySelectComponent } from './components/merchant-facility-select/merchant-facility-select.component';
import { NavTopBarComponent } from './components/nav-top-bar/nav-top-bar.component';
import { OrgSwitcherComponent } from './components/org-switcher/org-switcher.component';
import { PaginationDisplayTextComponent } from './components/pagination-display-text/pagination-display-text.component';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';
import { SessionDeleteComponent } from './components/session-delete/session-delete.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { TenantTextComponent } from './components/tenant-text/tenant-text.component';
import { TransactionDetailsComponent } from './components/transaction-details/transaction-details.component';
import { TransactionErrorTemplatesComponent } from './components/transaction-error-templates/transaction-error-templates.component';
import { TransactionsSnackbarComponent } from './components/transactions-snackbar/transactions-snackbar.component';
import { IfPermissionDirective } from './directives/if-permission.directive';
import { SubmitFormDirective } from './directives/submit-form.directive';
import { AssetHashPipe } from './pipes/asset-hash.pipe';
import { CentsPipe } from './pipes/cents.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { MerchantFriendlyNamePipe } from './pipes/merchant-friendly-name.pipe';
import { SnakeCaseToTextPipe } from './pipes/snake-case-to-text.pipe';
import { TimeZonePipe } from './pipes/timezone.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { UpperCaseFirstPipe } from './pipes/upper-case-first.pipe';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatOptionModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSelectModule,
    MatIconModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatMenuModule,
    MatToolbarModule,
    MatTooltipModule,
    MatListModule,
    MatTreeModule,
    MatExpansionModule,
    MatSidenavModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    IMaskModule,
    CollapseModule,
    IconModule,
    ButtonModule,
    NumberCounterModule,
    MatDatepickerModule,
    MatLuxonDateModule,
    IconButtonComponent,
    VisuallyHiddenComponent,
    NgxMatTimepickerModule.setLocale(environment.DEFAULT_LOCALE),
    ButtonComponent,
    IconComponent,
    VStackComponent,
    MainPageLayoutComponent,
    MetricsSectionComponent,
    BackLinkComponent,
    AlertComponent,
    AlertIconComponent,
    AlertBodyComponent,
    AlertTitleComponent,
    AlertDescriptionComponent,
    CompositeNameComponent,
    StackComponent,
    HStackComponent,
    DividerComponent,
    BadgeComponent,
    CardComponent,
    CardBodyComponent,
    CardHeaderComponent,
    DialogActionsComponent,
    ModalComponent,
    ModalHeaderComponent,
    ModalTitleComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    CloseButtonComponent,
    DataViewsModule,
    VStackComponent,
    CheckboxComponent,
    CheckboxWrapComponent,
    CheckboxInputComponent,
    CheckboxLabelComponent,
    CheckboxControlComponent,
    HelpTextComponent,
    ComboboxComponent,
    ComboboxOptionComponent,
    ComboboxOptionBaseComponent,
    ComboboxSelectionIndicatorCheckboxComponent,
    ComboboxOptionDirective,
    PdfJsViewerModule,
  ],
  declarations: [
    TimeZonePipe,
    DatePickerDialogComponent,
    TransactionDetailsComponent,
    MenuComponent,
    FooterComponent,
    ErrorDialogComponent,
    InactivityDialogComponent,
    SessionDeleteComponent,
    TruncatePipe,
    CentsPipe,
    AssetHashPipe,
    UpperCaseFirstPipe,
    SnakeCaseToTextPipe,
    AlertOrgStatusComponent,
    TransactionTableWithPaginationComponent,
    OrgSwitcherComponent,
    TransactionsSnackbarComponent,
    TenantTextComponent,
    FileSizePipe,
    MidListFilterComponent,
    PaymentMethodComponent,
    SubmitFormDirective,
    CheckboxContentToggleComponent,
    DatePickerComponent,
    ErrorTemplatesComponent,
    TransactionErrorTemplatesComponent,
    FilterAccordionComponent,
    DownloadingFileComponent,
    MerchantFacilitySelectComponent,
    MerchantFriendlyNamePipe,
    TerminalNicknamePipe,
    PaginationDisplayTextComponent,
    DateTimePickerDialogComponent,
    DateTimeRangeDialogComponent,
    HeroMetricComponent,
    HeroMetricSubtextComponent,
    IfPermissionDirective,
    PermissionOutlineComponent,
    SideNavComponent,
    NavTopBarComponent,
  ],
  exports: [
    TimeZonePipe,
    DatePickerDialogComponent,
    TransactionDetailsComponent,
    MenuComponent,
    FooterComponent,
    SessionDeleteComponent,
    MatSidenavModule,
    TruncatePipe,
    CentsPipe,
    AssetHashPipe,
    UpperCaseFirstPipe,
    SnakeCaseToTextPipe,
    AlertOrgStatusComponent,
    TransactionTableWithPaginationComponent,
    TransactionsSnackbarComponent,
    TenantTextComponent,
    FileSizePipe,
    MidListFilterComponent,
    PaymentMethodComponent,
    MatDatepickerModule,
    SubmitFormDirective,
    CheckboxContentToggleComponent,
    DatePickerComponent,
    ErrorTemplatesComponent,
    FilterAccordionComponent,
    DownloadingFileComponent,
    MerchantFacilitySelectComponent,
    MerchantFriendlyNamePipe,
    TerminalNicknamePipe,
    PaginationDisplayTextComponent,
    DateTimePickerDialogComponent,
    DateTimeRangeDialogComponent,
    HeroMetricComponent,
    HeroMetricSubtextComponent,
    IfPermissionDirective,
    SideNavComponent,
    NavTopBarComponent,
    MainPageLayoutComponent,
    MetricsSectionComponent,
    BackLinkComponent,
    VStackComponent,
    CheckboxComponent,
    CheckboxWrapComponent,
    CheckboxInputComponent,
    CheckboxLabelComponent,
    CheckboxControlComponent,
    HelpTextComponent,
    ComboboxComponent,
    ComboboxOptionComponent,
    ComboboxOptionBaseComponent,
    ComboboxSelectionIndicatorCheckboxComponent,
    ComboboxOptionDirective,
    PdfJsViewerComponent,
  ],
})
export class SharedModule {}
