import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.prod';
import { AcceptInvitationResp, GetInviteListResponse } from '@interfaces/invitation';
import { GetUserMembership, Membership } from '@interfaces/memberships';
import { GetUserProfileResponse, UpdateUserProfileReq, UpdateUserProfileResponse, User } from '@interfaces/user';
import { QueryService } from '@mx51/ngx-tanstack-query-adapter';
import { get } from 'lodash';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';

import { QueryKeys } from './query-keys.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, private queryService: QueryService, private queryKeys: QueryKeys) {}

  userDetail: User;
  memberships: Membership[];
  selectedMembership: Membership;
  defaultMembership: Membership;
  cachedUser: any;

  // [VAS-134] Short-term approach to org-level feature flagging by
  // attaching information in organisation membership
  orgHasDisputesEnabled: boolean;
  orgHasReportsEnabled: boolean;
  orgHasDashboardLandingEnabled: boolean;
  orgTransactionListingEnabled: boolean;
  orgTransactionAggregationEnabled: boolean;

  getMemberships() {
    if (!this.cachedUser) {
      const headers = new HttpHeaders({
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      });

      this.cachedUser = this.http.get<GetUserMembership>('/api/v1/me', { headers }).pipe(
        map((res) => {
          this.memberships = res.org_memberships;

          let defaultMembership = res.org_memberships.find((membership) => {
            return membership.id === res.default_org_membership;
          });

          // No default membership will be found if existing default membership is closed
          if (!defaultMembership && res.org_memberships.length > 0) {
            defaultMembership = res.org_memberships.reduce((prev, cur) =>
              cur.create_ts_ms < prev.create_ts_ms ? cur : prev
            );
          }

          this.setDefaultMembership(defaultMembership);

          this.userDetail = res.user;

          return res;
        }),
        catchError(() => {
          return of({ organisations: [] });
        }),
        publishReplay(1),
        refCount()
      );
    }

    return this.cachedUser;
  }

  setDefaultMembership(membership: Membership) {
    this.defaultMembership = membership;
  }

  getDefaultMembership(): Membership {
    return this.defaultMembership;
  }

  setSelectedMembership(orgId?: string) {
    let membership: Membership;

    if (orgId === get(this.selectedMembership, 'organisation.id', false)) {
      return null;
    }

    if (orgId) {
      membership = this.memberships.find((el) => el.organisation.id === orgId);
    } else {
      // Use default
      membership = this.defaultMembership;
    }

    this.selectedMembership = membership;

    // Retrieve org-level enabled feature flag information
    // return true if key does not yet exist for backwards compatibility
    this.orgHasDisputesEnabled = this.selectedMembership?.organisation?.feature_disputes_enabled ?? true;
    this.orgHasReportsEnabled = this.selectedMembership?.organisation?.feature_merchant_reports_enabled ?? false;
    this.orgHasDashboardLandingEnabled =
      this.selectedMembership?.organisation?.feature_dashboard_landing_enabled ?? true;
    this.orgTransactionListingEnabled =
      this.selectedMembership?.organisation?.feature_transaction_listing_enabled ?? true;
    this.orgTransactionAggregationEnabled =
      this.selectedMembership?.organisation?.feature_transaction_aggregation_enabled ?? true;
  }

  hasMembership(orgId: string): boolean {
    if (this.memberships.find((el) => el.organisation.id === orgId)) return true;
    return false;
  }

  getSelectedMembership(): Membership {
    return this.selectedMembership;
  }

  getSelectedMembershipPermissionList(): string[] {
    return get(this.selectedMembership, 'permissions', []);
  }

  getMembershipList(): Membership[] {
    return this.memberships;
  }

  getSelectedOrganisationId() {
    return get(this.selectedMembership, 'organisation.id', null);
  }

  clearData() {
    this.cachedUser = null;
    this.userDetail = null;
    this.selectedMembership = null;
    this.memberships = null;
  }

  getUserDetail(): User {
    return this.userDetail;
  }

  getProfile() {
    const url = `${environment.api_base}me/user-profile`;
    const headers = new HttpHeaders({ 'global-error-handler': 'false' });
    return this.http.get<GetUserProfileResponse>(url, { headers });
  }

  updateProfile(options) {
    const url = `${environment.api_base}me/user-profile`;
    const headers = new HttpHeaders({ 'global-error-handler': 'false' });
    return this.http.patch<GetUserProfileResponse>(url, options, { headers });
  }

  getUserProfile_QUERY() {
    return this.queryService.useQuery<GetUserProfileResponse, HttpErrorResponse>({
      queryKey: this.queryKeys.secure.me.userProfile(),
      queryFn: () => firstValueFrom(this.getProfile()),
    });
  }

  updateUserProfile_QUERY() {
    return this.queryService.useMutation<UpdateUserProfileResponse, HttpErrorResponse, UpdateUserProfileReq>({
      mutationFn: (payload) => firstValueFrom(this.updateProfile(payload)),
      onSuccess: () => {
        this.queryService.queryClient.invalidateQueries({
          queryKey: this.queryKeys.secure.me.all(),
        });
      },
    });
  }

  updatePassword(options) {
    const url = `${environment.api_base}me/password`;
    const headers = new HttpHeaders({ 'global-error-handler': 'false' });
    return this.http.put(url, options, { headers, observe: 'response' });
  }

  updateDefaultOrgMembership(options) {
    const url = `${environment.api_base}me/default-orgmembership`;
    const headers = new HttpHeaders({ 'global-error-handler': 'false' });
    return this.http.put(url, options, { headers, observe: 'response' });
  }

  getInvitationList() {
    const url = `${environment.api_base}me/invitations`;
    const headers = new HttpHeaders({ 'global-error-handler': 'false' });
    return this.http.get<GetInviteListResponse>(url, { headers });
  }

  acceptInvitation(invitationId: string, options?) {
    const url = `${environment.api_base}me/invitations/${invitationId}/accept`;
    const headers = new HttpHeaders({ 'global-error-handler': 'false' });
    return this.http.post<AcceptInvitationResp>(url, options, { headers, observe: 'response' });
  }
}
