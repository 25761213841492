import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconModule } from '@mx51/shared-components';

@Component({
  selector: 'app-back-link',
  templateUrl: './back-link.component.html',
  standalone: true,
  imports: [RouterModule, IconModule],
})
export class BackLinkComponent {
  @Input() link: string | any[];
  @Input() label: string;
}
