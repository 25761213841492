<div mxc-modal>
  <div mxc-modal-header>
    <h2 mxc-modal-title class="mxc-m-0" id="dialog-heading">Are you still there?</h2>
  </div>
  <div mxc-modal-body>
    <p>
      You've been away for a little while.
      <br />
      You'll be logged out due to inactivity shortly.
    </p>
  </div>

  <hr mxc-divider />

  <div mxc-modal-footer>
    <app-dialog-actions>
      <button mxc-button colorScheme="action" size="lg" variant="solid" (click)="handleExtendSession()">
        Stay logged in
      </button>
    </app-dialog-actions>
  </div>
</div>
