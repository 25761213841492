import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-actions',
  standalone: true,
  template: `
    <ng-content></ng-content>
  `,
  imports: [CommonModule],
  styleUrls: ['./dialog-actions.scss'],
})
export class DialogActionsComponent {}
