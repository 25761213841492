import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '@modules/shared/components/error-dialog/error-dialog.component';
import { ErrorMessageService } from 'app/services/error-message.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(public matDialog: MatDialog, public errorMessageService: ErrorMessageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // skip error catch if url is in the ignore list
    const useGlobalHandler = req.headers.get('global-error-handler') !== 'false';
    if (!useGlobalHandler) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((event) => {
        this.handleError(event, req.url);
        return throwError(event);
      })
    );
  }

  handleError(errorResponse: HttpErrorResponse, url: string): void {
    switch (errorResponse.status) {
      case 401: {
        // 401 error is handled by session interceptor
        break;
      }
      default: {
        // [MERSERV-1479] Global Backend error handler
        const errorTitle = this.getTitle(url);
        const errorMsg = this.getMessage(errorResponse);

        this.matDialog.open(ErrorDialogComponent, {
          data: { errorTitle, errorMsg },
          disableClose: false,
          autoFocus: false,
          width: '360px',
        });
      }
    }
  }

  getTitle(url: string) {
    const prefix = url
      .replace(/api\/v1\/(authz\/)?/g, '')
      .replace(/[-\/]/g, ' ')
      .split(' ')
      .map((title) => title.charAt(0).toUpperCase() + title.substr(1).toLowerCase())
      .join(' ');
    return prefix + ' could not be processed at this time';
  }

  getMessage(errorResponse: HttpErrorResponse) {
    return this.errorMessageService.getMessage(errorResponse);
  }
}
