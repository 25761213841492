<div class="wrapper">
  <div class="response-header">
    <div class="response-icon">
      <msp-icon [config]="{ name: 'icon-cross-full', size: 'large', fill: 'error', ariaLabel: 'error' }"></msp-icon>
    </div>

    <div class="response-message">
      <div class="error-message">{{ data.errorTitle }}</div>
    </div>

    <div>
      <button mat-icon-button [mat-dialog-close]><mat-icon class="close-icon">close</mat-icon></button>
    </div>
  </div>

  <div class="transaction-result">
    <div class="transaction-info">
      <span>
        <br />
        Error: {{ data.errorMsg }}
      </span>
    </div>
  </div>
</div>
