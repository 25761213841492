import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { SECURE_ORG_NOT_FOUND_ROUTE } from '@modules/shared/constants/routing';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FeatureFlagLoaderService } from './services/feature-flag-loader.service';
import { SecureTenantConfigService } from './services/secure-tenant-config.service';
import { UserService } from './services/user.service';

@Injectable({
  providedIn: 'root',
})
export class SecureGuard {
  constructor(
    private secureTenantConfigService: SecureTenantConfigService,
    private featureFlagLoaderService: FeatureFlagLoaderService,
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const paramOrgId = route.params.orgId;
    return this.activateOrRedirect(paramOrgId);
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const paramOrgId = route.params.orgId;
    return this.activateOrRedirect(paramOrgId);
  }

  activateOrRedirect(orgId: string) {
    if (this.userService.getSelectedMembership()) {
      return of(true);
    }
    return forkJoin([
      this.userService.getMemberships(),
      this.secureTenantConfigService.loadSecureTenantConfig(),
      this.featureFlagLoaderService.loadFeatures(),
    ]).pipe(
      map(() => {
        if (orgId) {
          const hasMembership = this.userService.hasMembership(orgId);
          if (!hasMembership) {
            this.router.navigate([SECURE_ORG_NOT_FOUND_ROUTE]);
          }
          this.userService.setSelectedMembership(orgId);
        }
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }
}
