import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class A11yService {
  autoFocus: boolean;

  // NOTE: Since Screenreaders like JAWS and NVDA intercepts the Keyboard "Enter" event we cannot rely on listening
  // to it. We detect keyboard usage thru the MouseEvent. When screenX and screenY are 0, it means the "click" was
  // triggered via Keyboard.
  onKeyboardNavigate(event: MouseEvent) {
    this.autoFocus = event.screenX === 0 && event.screenY === 0;
  }

  autoFocusOnMain(main: ElementRef) {
    if (this.autoFocus) main.nativeElement.focus();
    this.autoFocus = false;
  }
}
