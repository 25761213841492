import { NgModule } from '@angular/core';
import { mapToCanActivate, mapToCanActivateChild, RouterModule, Routes, TitleStrategy } from '@angular/router';
import { AuthGuard } from '@mx51/login-lib';

import { CustomTitleStrategy } from './custom-title-strategy';
import { SecureGuard } from './secure.guard';

// At this stage there is no guides on how to test dynamic imports in Angular 8+:
// loadChildren: () => import('some.module').then(m => m.SomeModule)
// TODO: create e2e test that will visit all sub-routes defined in this file
/* istanbul ignore next */
export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('app/modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'secure',
    loadChildren: () => import('app/modules/secure/secure.module').then((m) => m.SecureModule),
    canActivate: mapToCanActivate([AuthGuard, SecureGuard]),
    canActivateChild: mapToCanActivateChild([AuthGuard, SecureGuard]),
  },
  {
    path: 'public',
    loadChildren: () => import('app/modules/public/public.module').then((m) => m.PublicModule),
  },
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  { path: 'login', redirectTo: '/auth/login' },
  { path: 'reset-password', redirectTo: 'auth/reset-password' },
  { path: '**', redirectTo: '/auth/login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false, // Set to true to debug routes
      paramsInheritanceStrategy: 'always', // Allows for access to parent route params from child
    }),
  ],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: CustomTitleStrategy }],
})
export class AppRoutingModule {}
