import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ErrorMessageService } from '@mx51/core-tools';
import { LoginLibService } from '@mx51/login-lib';
import { EulaSection } from '@mx51/login-lib/lib/shared/models/login-info-text';
import { get } from 'lodash';

export interface FeatureConfigs {
  selfService?: {
    requestOptions: string[];
    terminalTypes: string[];
    showDisclaimer: boolean;
    terminalCountMax: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  tenantConfig: any;

  constructor(
    private http: HttpClient,
    private loginLibService: LoginLibService,
    private errorMessageService: ErrorMessageService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  async loadConfiguration(): Promise<any> {
    const headers = new HttpHeaders({
      'global-error-handler': 'false',
      'Content-type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    });

    await this.http
      .get('assets/config.json', { headers })
      .toPromise()
      .then((res) => {
        this.tenantConfig = res;
      })
      .catch(() => {
        // failed - set default to mx51
        // TODO: not sure if eng should be a fallback
        this.tenantConfig = { tenant: 'eng', theme: 'mx51' };
      });
  }

  setTheme() {
    const existingStylesheet = document.head.querySelectorAll(`link[rel="stylesheet"].theme`);

    if (existingStylesheet.length === 0) {
      const timeQS = Math.random();
      const linkEl = document.createElement('link');
      linkEl.classList.add('theme');
      linkEl.rel = 'stylesheet';
      linkEl.href = `/assets/theme/${this.tenantConfig.theme}.css?v=${timeQS}`;
      document.head.appendChild(linkEl);
    }
  }

  /**
   * Only used in dev mode to switch configs
   */
  setChameleonUITheme() {
    (this.document.defaultView as any)?.loadTheme(this.tenantConfig.theme);
  }

  setLoginInfoText() {
    const textConfig = this.getTextConfig();
    const title = get(textConfig, 'login:title', '') as string;
    const consentList = get(textConfig, 'login:consent', []) as string[];
    const eula = get(textConfig, 'login:eula', null) as EulaSection[];
    const supportLink = get(textConfig, 'login:supportLink', null) as string;

    this.loginLibService.setLoginInfoText({
      title,
      consent: consentList.length > 0 ? consentList : null,
      eula,
      supportLink,
    });
  }

  setErrorMessageConfig() {
    const errorConfig = this.getErrorConfig();
    this.errorMessageService.loadErrorMap(errorConfig);
  }

  getFeatureConfigs(): FeatureConfigs {
    return get(this.tenantConfig, 'featureConfigs', {});
  }

  getCoBrandedConfig() {
    return get(this.tenantConfig, 'coBranded', false);
  }

  getTextConfig(): { [key: string]: string | string[] } {
    return get(this.tenantConfig, 'text', {});
  }

  getErrorConfig() {
    return get(this.tenantConfig, 'errors', {});
  }

  getSupportIconConfig(): string {
    return get(this.tenantConfig, 'supportIcon', 'OutlineMessageQuestion');
  }
}
