<a
  class="mxc-text-semantic-fg-action-default mxc-text-1 mxc-items-center mxc-inline-flex mxc-mt-2 mxc-mb-7"
  [routerLink]="link"
>
  <msp-icon [config]="{ name: 'icon-chevron-left', size: 'small' }" class="mxc-flex mxc-mr-1"></msp-icon>
  @if(label) {
  {{ label }}
  } @else {
  <ng-content select="[label]"></ng-content>
  }
</a>
