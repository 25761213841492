import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { environment } from '@environments/environment';
import { get } from 'lodash';

import { ConfigService } from './services/config.service';

@Injectable({ providedIn: 'root' })
export class CustomTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title, private configService: ConfigService) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);

    const textConfig = this.configService.getTextConfig();
    const appTitle = get(textConfig, 'app:title', 'Merchant Dashboard') as string;

    if (title?.length > 0) {
      this.title.setTitle(`${title} | ${appTitle}`);
    } else {
      this.title.setTitle(appTitle);

      if (!environment.production) {
        console.log(`No title set for ${routerState.url}, falling back to "${appTitle}"`);
      }
    }
  }
}
