import { Injectable } from '@angular/core';
import { FeatureKey } from '@modules/shared/interfaces/feature';

import { FeatureFlagLoaderService } from './feature-flag-loader.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  get isBusinessSettingsEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.BusinessSettings);
  }

  get isDisputesEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.Disputes);
  }

  get isDisputesViaFileIngestionEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.DisputesViaFileIngestion);
  }

  get isDashboardGraphEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.DashboardGraph);
  }

  get isInsightsAveragePurchaseGraphEnabled(): boolean {
    return this.isInsightsServiceEnabled && this.isFeatureEnabled(FeatureKey.InsightsAveragePurchaseGraph);
  }

  get isInsightsChargebackMetricsEnabled(): boolean {
    return this.isInsightsServiceEnabled && this.isFeatureEnabled(FeatureKey.InsightsChargebackMetrics);
  }

  get isInsightsNewVsReturningCustomerGraphEnabled(): boolean {
    return this.isInsightsServiceEnabled && this.isFeatureEnabled(FeatureKey.InsightsNewVsReturningCustomersGraph);
  }

  get isInstoreApiEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.InstoreApi);
  }

  get isMerchantLogoUploadEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.MerchantLogoUpload);
  }

  get isMerchantReportsEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.MerchantReports);
  }

  get isMerchantServiceRequestsEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.MerchantServiceRequests);
  }

  get isSettlementsEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.Settlements);
  }

  get isTogglePaymentLogosEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.TogglePaymentLogos);
  }

  get isTransactionsEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.Transactions);
  }

  get isTerminalTransactionsReportEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.TerminalTransactionsReport);
  }

  get isUserManagementEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.UserManagement);
  }

  get isUserManagementDisclaimerEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.UserManagementDisclaimer);
  }

  get isVasConnectServiceEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.VasConnectService);
  }

  get isVerticalNavigationEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.VerticalNavigation);
  }

  get isMerchantFacilityAccessEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.MerchantFacilityAccess);
  }

  get isEditOwnContactInfoEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.EditOwnContactInfo);
  }

  get isEditOrgMemberContactInfoEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.EditOrgMemberContactInfo);
  }

  private get isInsightsServiceEnabled(): boolean {
    return this.isFeatureEnabled(FeatureKey.InsightsService);
  }

  constructor(private featureFlagLoaderService: FeatureFlagLoaderService) {}

  private isFeatureEnabled(featureKey: FeatureKey): boolean {
    return this.featureFlagLoaderService.featureSet.has(featureKey);
  }
}
