import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SsoIssuer } from '@interfaces/user';
import { LoginApiService, LoginLibRoute, LoginLibService, SingleSignOnRoute } from '@mx51/login-lib';

import { FeatureFlagService } from './feature-flag.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    public router: Router,
    private loginService: LoginLibService,
    private loginApiService: LoginApiService,
    private userService: UserService,
    private featureFlags: FeatureFlagService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) {}

  logout(): void {
    this.loginApiService.logout().subscribe(
      () => this.redirectToLogin(),
      () => this.redirectToLogin()
    );
  }

  // Clears session subjects and redirects user to the login page.
  redirectToLogin(redirectString?: string): void {
    this.clearModals();
    this.loginService.clearCookies();
    this.loginService.clearTimers();

    // Clearing session storage to prevent any data leakage as we use this to store
    // Offers Opt-in data of user to improve UX
    window.sessionStorage.clear();

    let query = {};

    if (redirectString) {
      query = { queryParams: { redirect: encodeURIComponent(redirectString) } };
    }

    const { sso_issuer } = this.userService.getUserDetail() ?? {};
    if (sso_issuer === SsoIssuer.Amazon) {
      this.router.navigate([`/auth/${SingleSignOnRoute.Amazon}`], query);
    } else if (sso_issuer === SsoIssuer.Mx51Okta) {
      this.router.navigate([`/auth/${SingleSignOnRoute.Mx51Okta}`], query);
    } else {
      this.router.navigate([`/auth/${LoginLibRoute.Login}`], query);
    }
  }

  clearModals(): void {
    this.matDialog.closeAll();
    this.matSnackBar.dismiss();
  }

  getFirstAvailablePageRoute(orgId: string) {
    switch (true) {
      case this.featureFlags.isDashboardGraphEnabled && this.userService.orgHasDashboardLandingEnabled:
        return `/secure/${orgId}/dashboard`;

      case this.featureFlags.isTransactionsEnabled:
        return `/secure/${orgId}/transactions`;

      case this.featureFlags.isSettlementsEnabled:
        return `/secure/${orgId}/settlements`;

      case this.featureFlags.isDisputesEnabled:
        return `/secure/${orgId}/disputes`;

      default:
        return `/secure/${orgId}/settings`;
    }
  }
}
