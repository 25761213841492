<div
  role="main"
  id="main"
  aria-labelledby="main-header"
  #main
  tabindex="-1"
  class="mxc-flex mxc-flex-col"
  [ngClass]="featureFlags.isVerticalNavigationEnabled ? 'mxc-min-h-full' : 'main-content'"
>
  <div class="mxc-bg-semantic-bg-brand-emphasis-default">
    <h1 id="main-header" class="centered-content type-basic mxc-my-0 mxc-text-5 md:mxc-text-7">
      @if(pageTitle) {
      {{ pageTitle }}
      } @else {
      <ng-content select="[pageTitle]"></ng-content>
      }
    </h1>
  </div>

  <ng-content select="app-metrics-section"></ng-content>

  <div>
    <div class="centered-content xl:mxc-my-0 xl:mxc-mx-auto mxc-w-full">
      <ng-content select="app-back-link"></ng-content>

      <ng-content></ng-content>
    </div>
  </div>
</div>
