import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CSP_NONCE, NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { METADATA } from '@environments/build-metadata';
import { environment } from '@environments/environment';
import { ReleaseFlagsComponent } from '@modules/shared/components/release-flags/release-flags.component';
import { SharedModule } from '@modules/shared/shared.module';
import { AnalyticsModule, ErrorMessageModule, SentryModule, SplunkModule, TimeoutInterceptor } from '@mx51/core-tools';
import { LoginLibModule } from '@mx51/login-lib';
import { NgxTanstackQueryAdapterModule } from '@mx51/ngx-tanstack-query-adapter';
import { NgxTanstackQueryDebuggerModule } from '@mx51/ngx-tanstack-query-debugger';
import { AppHttpInterceptor } from 'app/interceptors/app-http-interceptor';
import { Settings } from 'luxon';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfigService } from './services/config.service';
import {
  EnvironmentVariableService,
  readEnvironmentVariablesFromDocument,
} from './services/environment-variable.service';
import { getAngularNonce } from './utils/get-angular-nonce';

const { ENV } = readEnvironmentVariablesFromDocument();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    MatSnackBarModule,
    MatSidenavModule,
    AppRoutingModule,
    HttpClientModule,
    ReleaseFlagsComponent,
    PdfJsViewerModule.forRoot(),
    LoginLibModule.forRoot({
      isProd: environment.production,
      apiBase: '/api/v1/auth',
      postLoginRoute: '/secure',
      parentModule: 'auth',
      buttonLabels: {
        loginContinue: 'Continue',
        mfaVerify: 'Verify',
        mfaResendSms: 'Request new verification code',
        newPasswordCreate: 'Create and log in',
        resetPasswordReset: 'Reset password',
      },
    }),
    SentryModule.forRoot({
      sentry: {
        dsn: 'https://829a3142dd734905bd59afbb10c4ba40@sentry.io/1453833',
        release: METADATA.tag,
      },
      enabled: METADATA.tag !== 'localhost',
    }),
    SplunkModule.forRoot({
      enabled: METADATA.tag !== 'empty' && ENV && ENV !== 'dev' && ENV !== 'local',
      sessionRecordingEnabled: false, // Currently an issue with a library used by Splunk (protobufjs) conflicts with our CSP so this cannot be enabled
      splunk: {
        realm: 'au0',
        rumAccessToken: METADATA.splunkRumToken,
        applicationName: 'merchant-dashboard-fe',
        deploymentEnvironment: ENV,
        version: METADATA.tag,
      },
    }),
    AnalyticsModule.forRoot({ version: METADATA.tag }),
    ErrorMessageModule.forRoot(),
    NgxTanstackQueryAdapterModule.forRoot({}),
    NgxTanstackQueryDebuggerModule,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: HTTP_INTERCEPTORS, useValue: new TimeoutInterceptor(), multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => configService.loadConfiguration(),
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (environmentVariableService: EnvironmentVariableService) => () => environmentVariableService.init(),
      deps: [EnvironmentVariableService],
      multi: true,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 6000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'legacy-snackbar',
      },
    },
    {
      provide: CSP_NONCE,
      useFactory: getAngularNonce,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    Settings.defaultZone = environment.DEFAULT_TIMEZONE;
    Settings.defaultLocale = environment.DEFAULT_LOCALE;
  }
}
